import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const NEW_MAIN_URL = process.env.REACT_APP_MAIN_URL;

const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

export const SITE_URL = 'https://live.avertisystems.com';

const auth = axios.create({
    baseURL: BASE_URL,
});

auth.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.data.message === 'Unauthenticated.') {
        if (window.location.pathname !== '/login') {
            window.location.pathname = '/login';
        }
    }
    throw error;
});

export async function get_all_stock() {
    const token = localStorage.getItem("AUTH_TOKEN");

    if (token === null) return null;
    headers["Authorization"] = "Bearer " + token;

    try {
        const response = await axios.get(`${SITE_URL}/api/v1/stock/all_get`, {
            headers: headers,
            });
          console.log("sssssssssssss", response)

        return response.data.finance.result;
    } catch (err) {
        if (err.response) {
            throw new Error(err.response.data.message);
        }
    }
}

export async function googleFinanceData() {
  const token = localStorage.getItem("AUTH_TOKEN");

  if (token === null) return null;
  try {
    const response = await axios.get('https://live.avertisystems.com/api/v1/serpapi/GOOG:NASDAQ', {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "Application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export async function googleFinanceMarketData(trend) {
  const token = localStorage.getItem("AUTH_TOKEN");


  if (token === null) return null;
  try {
    const response = await axios.get('https://live.avertisystems.com/api/v1/serpapi/trend/indexes', {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "Application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}


export  const stockgraphdata=async(range)=> {

  try {
    const response = await axios.get(`https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-chart`, {
      params: {
        interval: '5m',
        symbol: 'TSLA',
        range: range,
        region: 'US'
      },
      headers: {
        'X-RapidAPI-Key': 'd8eab7721cmshf7f21b0e88debffp10dcffjsnb5ffefe9fbfc',
        'X-RapidAPI-Host': 'apidojo-yahoo-finance-v1.p.rapidapi.com'
      }
    });
// console.log("response",response)
    return response.data.chart.result;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}

export default auth;


/////

export async function indexy(Dataform) { // Accept data as a parameter
  const token = localStorage.getItem("token");
  console.log("Token:", token);
  if (token === null) return null;

  // Define headers with Authorization
  const headers = {
    "Authorization": "Bearer " + token,
    "Content-Type": "application/json", // Adjust Content-Type if necessary
  };
  console.log("Request headers:", headers);



  try {
    // Make the POST API call
    const response = await axios.post(`https://live.avertisystems.com/api/v1/indexy/store`, Dataform, {
      headers: headers,
    });
    // Return the relevant data from the API response
    return response.data;
  } catch (err) {
    // Handle errors and throw appropriate messages
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("An error occurred while posting data.");
    }
  }
}

export async function boxie() {
  const token = localStorage.getItem("token");
  if (token === null) return null;
  headers["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(`https://live.avertisystems.com/api/v1/indexy/get`, {
      headers: headers,
    });

    console.log("Boxie response",response)

    return response.data
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }
}
