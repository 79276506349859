
import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Homies from "./Homies";

import Section1 from "./layouts/Section1";

function OutFits() {
  return (
    <div>
      <style>
        {`
          .tab-content {
            display: inline;
          }
          .nav-tabs .nav-link{
            width: 100%;
          }
          button.active{
           background: none !important;
          }
        
          .nav-item{
            display:flex;
            width: 33%;
            justify-content: center;
          }
        `}
      </style>
      <Section1/>
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
      style={{justifyContent:"space-between"}}
    >
      <Tab eventKey="home" title="Home">
       <Homies/> 
      </Tab>
      <Tab eventKey="profile" title="Profile" disabled >
        Tab content for Profile
      </Tab>
      <Tab eventKey="contact" title="" disabled>
        Tab content for Contact
      </Tab>
    </Tabs>
    </div>
  );
}

export default OutFits;