import React,{useState} from 'react'
import Innerpage from './Innerpage';
import './homes.css'
import Innerpage2 from './Innerpage2';
import Innerpage3 from './Innerpage3';
import Innerpage4 from './Innerpage4';
import Innerpage5 from './Innerpage5';
import Innerpage6 from './Innerpage6';
import Innerpage7 from './Innerpage7';
import Innerpage8 from './Innerpage8';
import Innerpage9 from './Innerpage9';

const Category = (props) => {
  const {handleclose,inputValue,inputValue1,selecteddtBox,setItemName,setFormData,formData,imageUrl} = props;
  

    const [redirectToCustomReport, setRedirectToCustomReport] = useState(false);
    
    const [category2,setCategory2 ] = useState(false);
    const [category3,setCategory3 ] = useState(false);
    const [category4,setCategory4 ] = useState(false);
    const [category5,setCategory5 ] = useState(false);
    const [category6,setCategory6 ] = useState(false);
    const [category7,setCategory7 ] = useState(false);
    const [category8,setCategory8 ] = useState(false);
    const [category9,setCategory9 ] = useState(false);

    const[categorydefine,setCategoryDefine]=useState("");
    props.categories(categorydefine);

    const handleButtonClick = (categoryName) => {
      // console.log("Selected Category:", categoryName ? categoryName : "" );
      setRedirectToCustomReport(true);
      setCategoryDefine(categoryName);
    };

  if (redirectToCustomReport) {
    return (
     <Innerpage handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
     formData={formData}
     setFormData={setFormData} imageUrl={imageUrl} />
    );
  }

  const handleButtonClick2=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory2(true);
    setCategoryDefine(categoryName)
  };
  if (category2){
    return(
      <Innerpage2 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />    );
  }

  const handleButtonClick3=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory3(true);
    setCategoryDefine(categoryName);
  };
  if (category3){
    return(
      <Innerpage3 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     );
  }

  const handleButtonClick4=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory4(true);
    setCategoryDefine(categoryName);
  };
  if(category4){
    return(
      <Innerpage4 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     )
  }

  const handleButtonClick5=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory5(true);
    setCategoryDefine(categoryName);
  };
  if(category5){
    return(
      <Innerpage5 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     )
  }

  const handleButtonClick6=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory6(true);
    setCategoryDefine(categoryName);
  };
  if(category6){
    return(
      <Innerpage6 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     )
  }

  const handleButtonClick7=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory7(true);
    setCategoryDefine(categoryName);
  };
  if(category7){
    return(
      <Innerpage7 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     )
  }

  const handleButtonClick8=(categoryName)=>{
    // console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory8(true);
    setCategoryDefine(categoryName);
  };
  if(category8){
    return(
      <Innerpage8 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     )
  }
  
  const handleButtonClick9=(categoryName)=>{
    console.log("Selected Category:", categoryName ? categoryName : "" );
    setCategory9(true);
  };
  if(category9){
    return(
      <Innerpage9 handleclose={handleclose} inputValue={inputValue} inputValue1={inputValue1} selecteddtBoxie={selecteddtBox} categorydefine={categorydefine} setItemName={setItemName}
      formData={formData}
      setFormData={setFormData} imageUrl={imageUrl} />     )
  }
  const handleButtonClick10=(categoryName)=>{
    console.log("Selected Category:", categoryName ? categoryName : "" );
    handleclose();
    
  };
 

  return (
    <div>
        <h2 className='htwoing'>What category is your item?</h2>
        <div className="container my-4">
    <div className="row md-6">
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick("TOP")} >
                <div className='liopl'>TOP</div>
            </div>
        </div>
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick2("BOTTOM")} >
                <div className='liopl'>BOTTOM</div>
            </div>
        </div>
    </div>
    <div className="row md-6">
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick3("OUTWEAR")} >
                <div className='liopl'>OUTWEAR</div>
            </div>
        </div>
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick4("ONE PIECE")} >
                <div className='liopl'>ONE PIECE</div>
            </div>
        </div>
    </div>
    <div className="row md-6">
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick5("BAG")} >
                <div className='liopl'>BAG</div>
            </div>
        </div>
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick6("SHOES")} >
                <div className='liopl'>SHOES</div>
            </div>
        </div>
    </div>
    <div className="row md-6">
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick7("ACCESSORY")} >
                <div className='liopl'>ACCESSORY</div>
            </div>
        </div>
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick8("JEWELRY")} >
                <div className='liopl'>JEWELRY</div>
            </div>
        </div>
    </div>
    <div className="row md-6">
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick9("SWIM")} >
                <div className='liopl'>SWIM</div>
            </div>
        </div>
        <div className="col-6 col-md-6">
            <div className="oo1boxie" onClick={() => handleButtonClick10("OTHER")} >
                <div className='liopl'>OTHER</div>
            </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default Category




